import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';

import Framework from '../../components/framework';
import Link from '../../components/framework/link';
import NavTop from '../../components/framework/nav-top';
import Seo from '../../components/framework/seo';
import useTranslation from '../../hooks/use_translation';

const Legal = () => {
	const { t } = useTranslation('legal');

	return (
		<Framework>
			<Seo title={t('title')} />
			<NavTop />

			<Container className="mt-5 pt-5">
				<h1>{t('t1')}</h1>

				<ListGroup variant="">
					<ListGroup.Item
						action
						as={Link}
						className="d-flex align-items-center list-group-item-nav px-3"
						to="/legal/privacy/"
					>
						{t('t2')}
						<span className="ms-auto">
							<ArrowRight />
						</span>
					</ListGroup.Item>
					<ListGroup.Item
						action
						as={Link}
						className="d-flex align-items-center list-group-item-nav px-3"
						to="/legal/terms/"
					>
						{t('t3')}
						<span className="ms-auto">
							<ArrowRight />
						</span>
					</ListGroup.Item>
					<ListGroup.Item
						action
						as={Link}
						className="d-flex align-items-center list-group-item-nav px-3"
						to="/legal/cookies/"
					>
						{t('t4')}
						<span className="ms-auto">
							<ArrowRight />
						</span>
					</ListGroup.Item>
					<ListGroup.Item
						action
						as={Link}
						className="d-flex align-items-center list-group-item-nav px-3"
						to="/legal/ai/"
					>
						{t('t5')}
						<span className="ms-auto">
							<ArrowRight />
						</span>
					</ListGroup.Item>
				</ListGroup>
			</Container>
		</Framework>
	);
};

export default Legal;
